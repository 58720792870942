<template>
  <div
    class="container p0"
    v-title
    :data-title="$t('i18n.dataOverview')"
    id="overview"
    :style="scaleCss"
  >
    <div class="head">
      <span v-if="weather">
        {{ weather.date }}&nbsp;&nbsp;&nbsp;&nbsp;
        {{ weather.week }}
      </span>
      <h4>巨立云电梯物联网平台运营概况-自定义</h4>
      <span v-if="weather">
        {{ weather.wea }}&nbsp;&nbsp;{{ weather.tem2 }} ~{{
          weather.tem1
        }}
        &nbsp;&nbsp;{{ weather.win[0] }}{{ weather.win_speed }}
      </span>
      <el-button
        v-if="!isCustomize && isAdmin"
        type="primary"
        @click="customizeFunc"
      >
        自定义数据
      </el-button>
      <el-button
        v-if="isCustomize && isAdmin"
        type="primary"
        @click="uadateDatabase"
      >
        数据库数据
      </el-button>
    </div>
    <div class="flex con">
      <div class="left">
        <div class="chart" id="bill">
          <span v-if="isSetting" class="setting" @click="changeValue(0)">
            设置
          </span>
          <div class="title bg">工单占比</div>
          <p class="title text-center m-t">工单总数</p>
          <div class="title text-center m-t5">
            <span class="blue">{{ showNumberData.billTotal }}</span>
            单
          </div>
          <div id="billChart" style="height: 228px"></div>
        </div>
        <div class="chart" id="year">
          <span v-if="isSetting" class="setting" @click="changeValue(1)">
            设置
          </span>
          <div class="title bg">年度台量统计</div>
          <div id="yearChart" style="height: 200px"></div>
        </div>
      </div>
      <div class="middle">
        <span v-if="isSetting" class="setting" @click="changeValue(2)">
          设置
        </span>
        <div id="absolute">
          <div class="item">
            <label>电梯总数</label>
            <div>
              <span>{{ showNumberData.elevatorTotal }}</span>
              &nbsp;台
            </div>
          </div>
          <div class="item m-t">
            <label>工单总数</label>
            <div>
              <span>{{ showNumberData.billTotal }}</span>
              &nbsp;单
            </div>
          </div>
          <div class="item m-t">
            <label>待维修</label>
            <div>
              <span>{{ showNumberData.waitNumber }}</span>
              &nbsp;单
            </div>
          </div>
        </div>
        <div id="elevatorChart" class="chart"></div>
      </div>
      <div class="right">
        <div class="chart" id="company">
          <span v-if="isSetting" class="setting" @click="changeValue(3)">
            设置
          </span>
          <div class="title bg">企业年度数据</div>
          <div id="companyChart">
            <div class="item">
              <div class="title">
                <span class="blue">{{ showNumberData.maintenanceNum }}</span>
                单
              </div>
              <p class="title m-t5">维保次数</p>
            </div>
            <div class="item">
              <div class="title">
                <span class="yellow">{{ showNumberData.repairNumber }}</span>
                单
              </div>
              <p class="title m-t5">维修次数</p>
            </div>
            <div class="item">
              <div class="title">
                <span class="blue">{{ showNumberData.ChinaNum }}</span>
                台
              </div>
              <p class="title m-t5">国内电梯数量</p>
            </div>
            <div class="item">
              <div class="title">
                <span class="yellow">{{ showNumberData.outElevatorNum }}</span>
                台
              </div>
              <p class="title m-t5">国外电梯数量</p>
            </div>
          </div>
        </div>
        <div class="chart" id="fault">
          <span v-if="isSetting" class="setting" @click="changeValue(4)">
            设置
          </span>
          <div class="title bg">故障分析</div>
          <ul class="forHelp">
            <li><span class="purple"></span>一般故障</li>
            <li><span class="blue"></span>困人故障</li>
          </ul>
          <div
            class="progress"
            v-for="item in chartData.faultChartData"
            :key="item"
          >
            <el-progress
              :show-text="false"
              :stroke-width="16"
              :percentage="parseInt(item.value)"
            ></el-progress>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex con" style="margin-top: -5px; margin-bottom: 10px">
      <div id="maintenance" class="chart half">
        <span v-if="isSetting" class="setting" @click="changeValue(5)">
          设置
        </span>
        <div class="title bg">保养数据</div>
        <div id="maintenanceChart" style="height: 350px"></div>
      </div>
      <div id="platform" class="chart half">
        <span v-if="isSetting" class="setting" @click="changeValue(6)">
          设置
        </span>
        <div class="title">平台电梯入网台量增长趋势图</div>
        <div id="platformChart" style="height: 350px"></div>
      </div>
      <div id="data" class="chart">
        <span v-if="isSetting" class="setting" @click="changeValue(7)">
          设置
        </span>
        <div class="title bg">企业经营数据占比</div>
        <div id="dataChart">
          <div id="dataChart1" class="chartone"></div>
          <div id="dataChart2" class="chartone"></div>
          <div id="dataChart3" class="chartone"></div>
        </div>
      </div>
    </div>
    <el-dialog :title="title[sIndex]" v-model="dialogFormVisible">
      <el-form label-width="120px">
        <el-form-item v-for="item in form" :key="item" :label="item.label">
          <el-input
            v-model="showNumberData[item.value]"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-for="item1 in chartData[changeKey]"
          :key="item1"
          :label="item1.name"
        >
          <el-input v-model="item1.value" autocomplete="off"></el-input>
        </el-form-item>
        <template v-for="item2 in arr1" :key="item2">
          <el-form-item v-if="sIndex == 7" :label="item2.name">
            <el-input v-model="item2.value" autocomplete="off"></el-input>
          </el-form-item>
        </template>
        <template v-for="item3 in arr2" :key="item3">
          <el-form-item v-if="sIndex == 7" :label="item3.name">
            <el-input v-model="item3.value" autocomplete="off"></el-input>
          </el-form-item>
        </template>
        <template v-for="item4 in arr3" :key="item4">
          <el-form-item v-if="sIndex == 7 && item4.name" :label="item4.name">
            <el-input v-model="item4.value" autocomplete="off"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <template #footer>
        <el-button @click="dialogFormVisible = false">
          {{ $t("i18n.cancel") }}
        </el-button>
        <el-button type="primary" @click="refreshChart">
          {{ $t("i18n.confirm") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";

export default {
  name: "Custom",
  setup(props, { emit }) {
    emit("public_header", false);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      elevatorChart: null,
      billChart: null,
      showNumberData: {
        billTotal: 209270,
        elevatorTotal: 32902,
        waitNumber: 24,
        maintenanceNum: 178641,
        repairNumber: 183507,
        ChinaNum: 32902,
        outElevatorNum: 0,
      },
      isSetting: false,
      isCustomize: false,
      isAdmin: false,
      yearChart: null,
      form: [],
      sIndex: "", // 设置的index
      title: [
        "工单占比",
        "年度台量统计",
        "地图",
        "企业年度数据",
        "故障分析",
        "保养数据",
        "平台电梯入网台量增长趋势图",
        "企业经营数据占比",
      ],
      chartData: {
        billData: [],
        yearData: [],
        mapdata: [],
        faultChartData: [
          { name: "调整不到位", value: (201 / (201 + 81)) * 100 },
          { name: "客户使用不当", value: (1122 / (1122 + 106)) * 100 },
          { name: "部件原因", value: (928 / (928 + 144)) * 100 },
          { name: "其他原因", value: (969 / (969 + 258)) * 100 },
        ],
        platformData: [],
        stationMaintenanceData: [],
      },
      changeKey: "",
      maintenanceChart: null,
      platformChart: null,
      dataChart1: null,
      arr1: [
        { value: 0, name: "一般故障" },
        { value: 0, name: "困人故障" },
      ],
      arr2: [],
      arr3: [],
      weather: "",
      dialogFormVisible: false,
      isOuterFrom: false,
      scaleCss: {}, // 缩放样式
    });
    onMounted(() => {
      setFullScreen();
    });

    const init = () => {
      getweatherApi();
      getUser();
      if (localStorage.getItem("isCustomize") != null) {
        state.isCustomize = localStorage.getItem("isCustomize") === "true";
      }
      if (
        localStorage.getItem("token") ==
        "USER1f64b67b-8e48-46eb-b876-6db012cb93dc"
      ) {
        // 正式环境form ziguangyun
        state.isOuterFrom = true;
      }
      state.isCustomize ? customizeData() : getInitData();
    };

    const setFullScreen = () => {
      var oHeight = document.getElementById("overview").offsetHeight; //显示器的高
      var oWidth = document.getElementById("overview").offsetWidth; //显示器的宽
      var HEIGHT = proxy.$defined.HEIGHT,
        WIDTH = proxy.$defined.WIDTH; // 页面高宽
      var scaleW = WIDTH / (oWidth + 18); //宽比例
      var scaleH = HEIGHT / (oHeight + 10); //高比例
      if (oWidth >= 1480) {
        state.scaleCss = {
          transform: "scale(" + scaleW + "," + scaleH + ")",
          transformOrigin: "left top",
          overflow: "hidden",
        };
      }
    };

    const getUser = async () => {
      var userId = localStorage.getItem("userId");
      let { data } = await proxy.$api.common.getUser(userId);
      state.isAdmin = data.userName === "admin";
    };

    const customizeFunc = () => {
      // 自定义数据
      state.isSetting = true;
      state.isCustomize = true;
      localStorage.setItem("isCustomize", state.isCustomize); // 存储是否自定义数据
      localStorage.setItem("chartData", JSON.stringify(state.chartData));
      localStorage.setItem(
        "showNumberData",
        JSON.stringify(state.showNumberData)
      );
      localStorage.setItem("arr1", JSON.stringify(state.arr1));
      localStorage.setItem("arr2", JSON.stringify(state.arr2));
      localStorage.setItem("arr3", JSON.stringify(state.arr3));
      if (localStorage.getItem("chartData")) {
        customizeData();
      }
    };

    const customizeData = () => {
      // 自定义数据
      state.chartData = JSON.parse(localStorage.getItem("chartData"));
      state.showNumberData = JSON.parse(localStorage.getItem("showNumberData"));
      state.arr1 = JSON.parse(localStorage.getItem("arr1"));
      state.arr2 = JSON.parse(localStorage.getItem("arr2"));
      state.arr3 = JSON.parse(localStorage.getItem("arr3"));
      setTimeout(() => {
        loadData();
      }, 500);
    };

    const uadateDatabase = () => {
      // 数据库数据
      state.isCustomize = false;
      state.isSetting = false;
      localStorage.setItem("isCustomize", state.isCustomize); // 存储是否自定义数据
      getInitData();
    };

    const getInitData = async () => {
      let { data } = await proxy.$api.monitor.getAllChartData();
      state.chartData.yearData = setData(
        JSON.parse(data.annualMaintenance),
        "time",
        "total"
      ); // 年度台量统计
      state.arr2 = setData(JSON.parse(data.areaRatio)); // 区域
      faultType(JSON.parse(data.faultType));
      setplatformData(JSON.parse(data.atPlatformElevators));
      state.arr3 = setData(JSON.parse(data.brandRatio), "name", "total");
      state.chartData.mapdata = setData(
        JSON.parse(data.chinaMap).eleProvinceList,
        "name",
        "number"
      );
      state.showNumberData.billTotal = JSON.parse(data.chinaMap).totalBillCount;
      state.showNumberData.elevatorTotal = JSON.parse(
        data.chinaMap
      ).elevatorTotal;
      state.showNumberData.waitNumber = JSON.parse(
        data.chinaMap
      ).waitRepairBillCount;
      state.showNumberData.repairNumber = JSON.parse(
        data.enterpriseAnnualData
      ).维修总数;
      state.showNumberData.maintenanceNum = JSON.parse(
        data.enterpriseAnnualData
      ).维保总数;
      state.showNumberData.ChinaNum = JSON.parse(
        data.enterpriseAnnualData
      ).国内电梯数;
      state.showNumberData.outElevatorNum = JSON.parse(
        data.enterpriseAnnualData
      ).国外电梯数;
      faultAnalyseData(JSON.parse(data.faultAnalyse));
      state.chartData.billData = setData(JSON.parse(data.workBillRatio));
      state.chartData.stationMaintenanceData = JSON.parse(
        data.stationMaintenanceData
      ).maintenanceMapByArea;
      loadData();
    };

    const setplatformData = (arr) => {
      var total = 0,
        array = [];
      for (var i = arr.length - 1; i >= 0; i--) {
        if (arr[i].createTime) {
          total = total + arr[i].countNum;
          var obj = {
            name: arr[i].createTime,
            value: total,
          };
          array.push(obj);
        }
      }
      state.chartData.platformData = array;
    };

    const faultType = (obj) => {
      state.arr1[0].value = obj.generateFault;
      state.arr1[1].value = obj.kunrenFault;
    };

    const faultAnalyseData = (obj) => {
      state.chartData.faultChartData.map((item, index) => {
        var j = index + 1;
        item.value =
          (obj["generalFault" + j] /
            (obj["generalFault" + j] + obj["trappedFault" + j])) *
          100;
      });
    };

    const setData = (arr, k1, k2) => {
      var array = [];
      if (arr instanceof Array) {
        arr.map((item) => {
          var obj = { name: item[k1], value: item[k2] };
          array.push(obj);
        });
      } else {
        for (var key in arr) {
          if (key !== "工单总数") {
            var obj = { name: key, value: arr[key] };
            array.push(obj);
          }
        }
      }
      return array;
    };

    const loadData = () => {
      state.elevatorChart = echarts.init( // eslint-disable-line
        document.getElementById("elevatorChart")
      );
      setelevatorChart();
      state.billChart = echarts.init(document.getElementById("billChart")); // eslint-disable-line
      setbillChart();
      state.yearChart = echarts.init(document.getElementById("yearChart")); // eslint-disable-line
      setyearChart();
      state.maintenanceChart = echarts.init( // eslint-disable-line
        document.getElementById("maintenanceChart")
      );
      setmaintenanceChart();
      state.platformChart = echarts.init( // eslint-disable-line
        document.getElementById("platformChart")
      );
      setplatformChart();
      state.dataChart1 = echarts.init(document.getElementById("dataChart1")); // eslint-disable-line
      setdataChart("故障类型占比", state.arr1, "dataChart1");
      state.dataChart2 = echarts.init(document.getElementById("dataChart2")); // eslint-disable-line
      setdataChart("区域电梯数量占比", state.arr2, "dataChart2");
      state.dataChart3 = echarts.init(document.getElementById("dataChart3")); // eslint-disable-line
      setdataChart("品牌占比", state.arr3, "dataChart3");
    };

    const getweatherApi = () => {
      var obj = {
        appid: "17932373",
        appsecret: "gxXsGZ5Q",
        version: "v1",
        ip: returnCitySN["cip"], // eslint-disable-line
      };
      proxy.$api.common.weatherApi(obj).then((res) => {
        state.weather = res.data.data[0];
      });
    };

    const changeValue = (v) => {
      state.dialogFormVisible = true;
      state.sIndex = v;
      if (v === 0) {
        // 工单占比
        state.form = [{ label: "工单总数", value: "billTotal" }];
        state.changeKey = "billData";
      } else if (v === 1) {
        // 年度台量统计
        state.form = [];
        state.changeKey = "yearData";
      } else if (v === 2) {
        // 地图统计
        state.form = [
          { label: "电梯总数", value: "elevatorTotal" },
          { label: "工单总数", value: "billTotal" },
          { label: "待维修", value: "waitNumber" },
        ];
        state.changeKey = "mapdata";
      } else if (v === 3) {
        // 企业年度数据
        state.form = [
          { label: "维保次数", value: "maintenanceNum" },
          { label: "维修次数", value: "repairNumber" },
          { label: "国内电梯数量", value: "ChinaNum" },
          { label: "国外电梯数量", value: "outElevatorNum" },
        ];
        state.changeKey = "";
      } else if (v === 4) {
        // 故障分析
        state.form = [];
        state.changeKey = "faultChartData";
      } else if (v === 5) {
        // 保养数据
        state.form = [];
        state.changeKey = "stationMaintenanceData";
      } else if (v === 6) {
        // 平台电梯入网数量增长趋势图
        state.form = [];
        state.changeKey = "platformData";
      } else if (v === 7) {
        // 企业经营占比
        state.form = [];
        state.changeKey = "";
      }
    };

    const refreshChart = () => {
      state.dialogFormVisible = false;
      var func = [
        "setbillChart",
        "setyearChart",
        "setelevatorChart",
        "",
        "",
        "setmaintenanceChart",
        "setplatformChart",
        "",
      ];
      if (func[state.sIndex]) {
        [func[state.sIndex]]();
      } else if (state.sIndex == 7) {
        setdataChart("故障类型占比", state.arr1, "dataChart1");
        setdataChart("区域电梯数量占比", state.arr2, "dataChart2");
        setdataChart("品牌占比", state.arr3, "dataChart3");
      }
      localStorage.setItem("chartData", JSON.stringify(state.chartData));
      localStorage.setItem(
        "showNumberData",
        JSON.stringify(state.showNumberData)
      );
      localStorage.setItem("arr1", JSON.stringify(state.arr1));
      localStorage.setItem("arr2", JSON.stringify(state.arr2));
      localStorage.setItem("arr3", JSON.stringify(state.arr3));
    };

    const setelevatorChart = () => {
      var optionMap = {
        tooltip: { trigger: "item" },
        visualMap: {
          //左侧小导航图标
          show: true,
          x: "left",
          y: "420px",
          splitList: [
            { start: 2000, end: 10000 },
            { start: 1000, end: 2000 },
            { start: 500, end: 1000 },
            { start: 100, end: 500 },
            { start: 0, end: 100 },
          ],
          color: ["#00169F", "#008AFF", "#1FBEFF", "#92E2FF", "#DAFFFF"],
        },
        series: [
          {
            //配置属性
            name: "数据",
            type: "map",
            mapType: "china",
            roam: false, // 鼠标放大缩小
            label: {
              normal: {
                show: true, //省份名称
              },
              emphasis: {
                show: false,
              },
            },
            data: state.chartData.mapdata, //数据
          },
        ],
      };
      state.elevatorChart.setOption(optionMap);
    };

    const setbillChart = () => {
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ",
        },
        color: ["#0C81FE", "#44F0E9", "#9A05F5", "#C59938", "#DAFFFF"],
        graphic: {
          //图形中间文字
          type: "text",
          left: "center",
          top: "center",
          style: {
            text: "工单占比",
            textAlign: "center",
            fill: "#fff",
            fontSize: 20,
          },
        },
        series: [
          {
            name: "工单数",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: true,
            label: {
              normal: {
                show: true,
                formatter: "{b}: {d}%",
              },
              emphasis: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            data: state.chartData.billData,
          },
        ],
      };
      state.billChart.setOption(option);
    };

    const setyearChart = () => {
      var arr = [];
      state.chartData.yearData.map((item) => {
        arr.push(item.value);
      });
      var option = {
        grid: { y: 30 },
        xAxis: {
          type: "category",
          data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
          axisLine: {
            lineStyle: {
              color: "#023C7A",
            },
          },
          axisTick: {
            lineStyle: { color: "transparent" }, // x轴刻度的颜色
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#023C7A",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dotted",
              color: "#023C7A",
            },
          },
          axisTick: {
            lineStyle: { color: "transparent" }, // x轴刻度的颜色
          },
        },
        textStyle: {
          fontSize: 15,
          color: "#8EC7DC",
        },
        series: [
          {
            data: arr,
            type: "line",
            symbol: "none",
            lineStyle: {
              normal: {
                color: "#0249A9",
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ // eslint-disable-line
                {
                  offset: 0,
                  color: "rgba(2, 60, 122, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(2, 60, 122, 0)",
                },
              ]),
            },
            smooth: true,
          },
        ],
      };
      state.yearChart.setOption(option);
    };

    const setmaintenanceChart = () => {
      var key = [],
        data = [];
      state.chartData.stationMaintenanceData.map((item) => {
        key.push(item.name);
        data.push(item.value);
      });
      var option = {
        grid: { y2: 45 },
        xAxis: {
          type: "category",
          data: key,
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            interval: 0,
            rotate: 45, //倾斜度 -90 至 90 默认为0
            margin: 2,
          },
          axisLine: {
            lineStyle: {
              color: "#023C7A",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dotted",
              color: "#023C7A",
            },
          },
          axisTick: {
            lineStyle: { color: "transparent" }, // x轴刻度的颜色
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} ",
          },
          axisLine: {
            lineStyle: {
              color: "#023C7A",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dotted",
              color: "#023C7A",
            },
          },
          axisTick: {
            lineStyle: { color: "transparent" }, // x轴刻度的颜色
          },
        },
        series: [
          {
            data: data,
            type: "bar",
            barWidth: 20,
            itemStyle: {
              normal: {
                label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 12,
                  },
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ // eslint-disable-line
                  { offset: 0, color: "#2647FD" },
                  { offset: 1, color: "#41A0F2" },
                ]),
              },
            },
          },
        ],
        textStyle: {
          fontSize: 15,
          color: "#8EC7DC",
        },
      };
      state.maintenanceChart.setOption(option);
    };

    const setplatformChart = () => {
      var key = [],
        data = [],
        addData = [];
      addData.push(0);
      state.chartData.platformData.map((item, index) => {
        key.push(item.name);
        data.push(item.value);
        if (index < state.chartData.platformData.length - 1) {
          addData.push(
            parseInt(state.chartData.platformData[index + 1].value - item.value)
          );
        }
      });
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          y2: 30,
        },
        xAxis: [
          {
            type: "category",
            data: key,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              lineStyle: {
                color: "#023C7A",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dotted",
                color: "#023C7A",
              },
            },
            axisTick: {
              lineStyle: { color: "transparent" }, // x轴刻度的颜色
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            axisLine: {
              lineStyle: {
                color: "transparent",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dotted",
                color: "#023C7A",
              },
            },
            axisTick: {
              lineStyle: { color: "transparent" }, // x轴刻度的颜色
            },
          },
        ],
        textStyle: {
          fontSize: 15,
          color: "#8EC7DC",
        },
        series: [
          {
            name: "保养台量",
            type: "bar",
            barWidth: 20,
            itemStyle: {
              normal: {
                label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 12,
                  },
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ // eslint-disable-line
                  { offset: 0, color: "#2647FD" },
                  { offset: 1, color: "#41A0F2" },
                ]),
              },
            },
            data: data,
          },
          {
            name: "增长量",
            type: "line",
            data: addData,
          },
        ],
      };
      state.platformChart.setOption(option);
    };

    const setdataChart = (title, data, element) => {
      var arr = [];
      data.map((item) => {
        arr.push(item.name);
      });
      var option = {
        title: {
          text: title,
          x: "center",
          y: 30,
          textStyle: {
            color: "#8EC6D8",
            fontSize: "14",
          },
        },
        legend: {
          x: "center",
          y: "bottom",
          type: "scroll",
          textStyle: {
            //图例文字的样式
            color: "#8EC7DC",
            fontSize: 14,
          },
          data: arr,
        },
        color: ["#0C81FE", "#44F0E9", "#9A05F5", "#C59938"],
        series: [
          {
            type: "pie",
            radius: "50%",
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  formatter: "{b}\n{d}%",
                },
              },
            },
            data: data,
          },
        ],
      };
      state[element].setOption(option);
    };

    init();

    return {
      ...toRefs(state),
      refreshChart,
      changeValue,
      customizeFunc,
      uadateDatabase,
    };
  },
};
</script>
<style lang="scss">
@import "../../common/scss/echart.scss";
</style>
